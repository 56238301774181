import { russianPaymentCardsNarrowedSet } from "app/constants/CurrencyConstants";

export const reducer: typeof window.kinosite.reducer  = {
	cinema: {
		address: 'пр. Михаила Нагибина 32 д. 2',
		place: null,
		phoneNumbers: ['8 (800) 555-04-15'],
		facebook: null,
		instagram: null,
		vk: 'https://vk.com/gorizont_cinema_emotion',
		twitter: null,
		odnoklassniki: null,
		youtube: null,
		app: {
			appstore: 'https://itunes.apple.com/ru/app/кинотеатр-горизонт/id1365642770?l=ru&ls=1&mt=8',
			googleplay: 'https://play.google.com/store/apps/details?id=ru.kinoplan.cinema.horizont',
		},
		payment: {
			active: true,
			card: russianPaymentCardsNarrowedSet,
		},
		maps: 'https://yandex.ru/maps/-/CBe~mUXBsC',
		selectButton: {
			options: [],
			selectedOptionValue: null,
		},
		soonPageIsEnabled: true,
	},
	feedback: true
};
